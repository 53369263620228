import React, { useEffect, useState } from "react";
import DepartI from "../Boutons/Actions/Depart";
import ArriveeI from "../Boutons/Actions/Arrivee";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../../../Utils/utils";
import Pointage from "../Boutons/Actions/Pointage";
import { useParams } from "react-router-dom";
import axios from "axios";

const PlanningInterEE = ({ intervenant, date, handleUpdatePointage, pt, id_EE }) => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [done, setDone] = useState(false);
    const [leContact, setLeContact] = useState({});
    const [pointageJour, setPointageJour] = useState({
        arrivee: "",
        depart: "",
        pause: "",
    });

    const [keyOfDate, setKeyOfDate] = useState(null);

    const contactsReducer = useSelector((state) => state.contactsReducer);

    
   
    const handleRemoveIntervenant = () => {
        let data = {};
        data = {
            contact_id : leContact.id,
            id_entreprise: id_EE,
        };
        let suppression = false;
        suppression = window.confirm("Voulez-vous vraiment supprimer cet intervenant? \nCela effacera tous ses pointages pour ce PT.") ;
        
        if (suppression) {
            axios({
                method: "delete",
                url: `${process.env.REACT_APP_API_URL}/pt/intervenant/${id}`,
                data: data,
                withCredentials: true,
            })
                .then(() => {
                    alert("Intervenant supprimé, tous ces pointages ont été éffacés pour ce PT.");
                    window.location = window.location;
                })
                .catch((err) => {
                    console.log(err);
                    alert("Erreur dans la suppréssion du contact");
                });
        }
       
        
    };

    useEffect(() => {
        // if (loading) {
        if (!isEmpty(contactsReducer[0])) {
            contactsReducer.map((contact) => {
                if (contact.id.toString() === intervenant.id_contact) {
                    setLeContact(contact);
                }
            });
        }

        if (intervenant.pointage_jour !== null) {
            setPointageJour(JSON.parse(intervenant.pointage_jour));
        }
        setLoading(false);

        if (!done) {
            for (const key in pointageJour) {
                let datePointage = pointageJour[key].jour;

                if (datePointage === date.toString()) {
                    setKeyOfDate(key);
                }
            }
            setDone(true);
        } else {
            if (!isEmpty(Object.entries(pointageJour))) {
                if (keyOfDate === null || date !== pointageJour[keyOfDate].jour) {
                    for (const key in pointageJour) {
                        let datePointage = pointageJour[key].jour;

                        if (datePointage === date) {
                            setKeyOfDate(key);
                            break;
                        } else {
                            setKeyOfDate(null);
                        }
                    }
                }
            }
        }
    }, [contactsReducer, loading, date, intervenant]);

    return (
        <div>
            <table className="table">
                <tbody>
                    <tr
                        style={{
                            marginTop: "15px",
                            borderBottom: "1px solid #f5f5f5",
                            color: "grey",
                            width: "100%",
                            backgroundColor: "#DCDCDC",
                        }}
                    >
                        {done && (
                            <>
                                <td style={{ width: "20%", textAlign: "center" }}>
                                    {leContact.prenom} {leContact.nom} 
                                    
                                </td>
                                {keyOfDate !== null ? (
                                    <>
                                        <td style={{ width: "20%", textAlign: "center" }}>
                                            {pointageJour[keyOfDate].arrivee}
                                        </td>
                                        <td style={{ width: "20%", textAlign: "center" }}>
                                            {pointageJour[keyOfDate].depart}
                                        </td>
                                        <td style={{ width: "20%", textAlign: "center" }}>
                                            {pointageJour[keyOfDate].pause}
                                        </td>
                                        <td style={{ width: "10%", textAlign: "center" }} className="d-flex">
                                            {" "}
                                            <Pointage
                                                contact={leContact}
                                                date={date}
                                                pointage={pointageJour[keyOfDate]}
                                                intervenant={intervenant}
                                                handleUpdatePointage={handleUpdatePointage}
                                                disabled={id !== "0" && pt.status === "clotures" ? true : false}
                                            />
                                        </td>  
                                        <td style={{ width: "10%", textAlign: "center" }}>
                                            
                                            <button style={{width: "auto",
                                            backgroundColor: "red",
                                            height: "auto",
                                            padding: "10px",
                                            color: "#fff",
                                            border: "none",
                                            borderRadius: "5px",
                                            position: "relative",
                                            right:"0"}} onClick={handleRemoveIntervenant}>Supprimer</button>
                                        </td>  
                                    </>
                                ) : (
                                    <>
                                    <td style={{ width: "20%", textAlign: "center" }}></td>
                                    <td style={{ width: "20%", textAlign: "center" }}></td>
                                    <td style={{ width: "20%", textAlign: "center" }}></td>
                                    <td className="d-flex" style={{ width: "10%", textAlign: "center" }}>
                                        {" "}
                                        <Pointage
                                            contact={leContact}
                                            date={date}
                                            intervenant={intervenant}
                                            handleUpdatePointage={handleUpdatePointage}
                                            disabled={id !== "0" && pt.status === "clotures" ? true : false}
                                        />
                                        
                                    </td>
                                    <td  style={{ width: "10%", textAlign: "center" }}>
                                        <button style={{width: "auto",
                                            backgroundColor: "red",
                                            height: "auto",
                                            padding: "10px",
                                            color: "#fff",
                                            border: "none",
                                            borderRadius: "5px",
                                            position: "relative",
                                            right:"0px"}} onClick={handleRemoveIntervenant}>Supprimer</button>
                                    </td>
                                    </>
                                    
                                )}
                            </>
                        )}

                        {/* <DepartI /> */}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PlanningInterEE;
