import React, { useState, useRef, useEffect, useCallback } from "react";
import "./MenuContextuel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "../PDF/PDFPT/ComponentToPrint";
import { useDispatch, useSelector } from "react-redux";
import { createPT, getPt } from "../../../Redux/actions/pt.actions";
import { getRisques, updateAnalyseRisque } from "../../../Redux/actions/risques.actions";
import { isEmpty, printPageStyle } from "../../../Utils/utils";
import axios from "axios";
import EnvoiMail from "./EnvoiMail/EnvoiMail";
import Loader from "../../Ui/Loader/Loader";
import { getRisquesByPT } from "../../../Utils/api/risques.api";

function MenuContextuel({ pt, showSend }) {
    const [modal, setModal] = useState(false);
    const [hoverIconOnce, setHoverIconOnce] = useState(false);

    const toggleModalMC = () => {
        setModal(!modal);
        setHoverIconOnce(true);
    };
    const ptReducer = useSelector((state) => [state.ptReducer]);
    const userReducer = useSelector((state) => state.userReducer);
    const risqueReducer = useSelector((state) => state.risquesReducer);

    const [loading, setLoading] = useState(true);
    const [done, setDone] = useState(false);
    const [wait, setWait] = useState(true);
    const [wait2, setWait2] = useState(true);

    const [ee, setEE] = useState({});
    const [eu, setEU] = useState({});
    const [eeCon, setEECon] = useState({});
    const [st, setST] = useState({});
    const [stCon, setSTCon] = useState({});
    const [thePt, setThePT] = useState({});
    const [orga, setOrga] = useState({});
    const [reco, setReco] = useState({});
    const [orga_sec, setOrga_Sec] = useState({});
    const [keyOrga, setKeyOrga] = useState([]);
    const [firstPartKeys, setFirstPartKeys] = useState([]);
    const [secondPartKeys, setSecondPartKeys] = useState([]);
    const [commentaires, setCommentaires] = useState({});
    const [avisChantier, setAvisChantier] = useState({});
    const [signatairesFin, setSignatairesFin] = useState({});

    const [modOpComInfos, setModOpComInfos] = useState({});
    const [theRisqueTitre, setTheRisqueTitre] = useState({});
    const [risques, setRisques] = useState({});

    const [validateur, setValidateur] = useState([]);

    const usineReducer = useSelector((state) => state.usineReducer);

    const dispatch = useDispatch();

    const parseRisques = useCallback(
        (fetchedRisques) => {
            if (!isEmpty(fetchedRisques[0])) {
                //regarde si première valeur empty
                let tmpArray = [];
                let tmpObject = {};
                fetchedRisques.map((risquePT) => {
                    if (risquePT.id_pt === pt.id) {
                        tmpArray.push(risquePT.titre_risque);
                        tmpObject[risquePT.id] = risquePT;
                    }
                });
                setTheRisqueTitre(tmpArray);
                setRisques(tmpObject);
            }
        },
        [pt]
    );

    const fetchPt = useCallback(async () => {
        if (userReducer.id !== undefined && pt.id !== undefined) {
            getRisquesByPT(pt.id)
                .then((res) => {
                    parseRisques(res.data);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
            // await dispatch(getRisques());
        }
    }, [userReducer.id, pt, parseRisques]);

    // When the menu icon is hovered or opened, fetch the PT risks
    useEffect(() => {
        if (hoverIconOnce) {
            fetchPt();
        }
    }, [fetchPt, hoverIconOnce]);

    const setDatas = useCallback(() => {
        setEE(JSON.parse(pt.entreprise_exterieure_PT));
        setEU(JSON.parse(pt.entreprise_utilisatrice_PT));
        setEECon(JSON.parse(pt.entreprise_exterieure_PT_contacts));
        setST(JSON.parse(pt.entreprise_ST_PT));
        setSTCon(JSON.parse(pt.entreprise_ST_PT_contacts));
        setOrga(JSON.parse(pt.organisation_PT));
        setReco(JSON.parse(pt.recommandation_PT));
        setOrga_Sec(JSON.parse(pt.orga_secours));
        setKeyOrga(Object.keys(orga_sec));
        setValidateur(JSON.parse(pt.validateur_PT));
        setCommentaires(JSON.parse(pt.commentaires));
        setAvisChantier(JSON.parse(pt.avis_chantier));
        setSignatairesFin(JSON.parse(pt.signataires_fin));

        if (pt.risque_mode_ope_com !== null) {
            setModOpComInfos(JSON.parse(pt.risque_mode_ope_com));
        }

        // TODO : Filter risques in backend on query because request is very slow

        //non empty analyse risque

        setDone(true);
    }, [pt, orga_sec]);

    useEffect(() => {}, [risques]);

    const handleCloture = async () => {
        if (window.confirm("Confirmez-vous vouloir clôturer ce PT ?")) {
            await axios({
                method: "put",
                url: `${process.env.REACT_APP_API_URL}/cloture/${pt.id}`,
                withCredentials: true,
            })
                .then(async () => {
                    alert("Cloture effectuée, vous pourrez quand même continuer à signer le PT");
                    await dispatch(getPt(userReducer.id_hash_usine));
                })
                .catch((err) => {
                    console.log(err);
                    alert("Erreur dans la clôture du pt");
                });
        }
    };

    useEffect(() => {
        if (loading) {
        } else {
            if (!done) {
                setDatas();
            } else {
                if (wait && orga_sec !== undefined && orga_sec !== null) {
                    setKeyOrga(Object.keys(orga_sec));

                    //setKeyRisque(Object.keys(mesurePrev));
                    setWait(false);
                } else {
                    if (wait2) {
                        // partie recommandations ORGA SECOURS
                        let arrayTmp = [];
                        for (let i = 0; i < Math.floor(keyOrga.length / 2); i++) {
                            arrayTmp.push(keyOrga[i]);
                        }
                        setFirstPartKeys(arrayTmp);
                        arrayTmp = [];
                        for (let i = Math.floor(keyOrga.length / 2); i < keyOrga.length; i++) {
                            arrayTmp.push(keyOrga[i]);
                        }
                        setSecondPartKeys(arrayTmp);

                        setWait2(false);
                    }
                }
            }
        }
    }, [loading, done, ptReducer, userReducer, wait, wait2, setDatas, orga_sec, keyOrga]);

    // partie où l'on récupère les données à imprimer
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: printPageStyle,
    });

    const url = `/pt/description/${pt.id}`;

    const handleCopy = async () => {
        if (!risques) {
            alert("Pas de risques à copier");
            return;
        }
        let code = "";
        let dateYear = new Date().getFullYear().toString();
        let dateMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");
        let numero = "";
        for (const [key, value] of Object.entries(usineReducer)) {
            if (value.id_hash.toString() === userReducer.id_hash_usine) {
                code = value.code.toString();
                numero = value.num_annuel_pt.toString();
            }
        }

        numero = numero.toString().padStart(3, "0");

        let numero_Pt = "PT-" + code + "-" + dateYear + "-" + dateMonth + "-" + numero;

        const numPT = numero_Pt;
        const datas = {
            nom_PT: pt.nom_PT + "_copie",
            description_phase_PT: pt.description_phase_PT,
            id_usine: userReducer.id_hash_usine,
            numero_PT: numPT,
            entreprise_utilisatrice_PT: JSON.parse(pt.entreprise_utilisatrice_PT),

            entreprise_exterieure_PT: JSON.parse(pt.entreprise_exterieure_PT),
            entreprise_exterieure_PT_contacts: JSON.parse(pt.entreprise_exterieure_PT_contacts),
            entreprise_ST_PT: JSON.parse(pt.entreprise_ST_PT),
            entreprise_ST_PT_contacts: JSON.parse(pt.entreprise_ST_PT_contacts),
            organisation_PT: JSON.parse(pt.organisation_PT),
            risque_mode_ope_com: JSON.parse(pt.risque_mode_ope_com),
            duplicating: true,
            duplicated_PT_id: pt.id,
        };

        await dispatch(createPT(datas));
    };

    return (
        <div className="menu_contextuel">
            <button onClick={toggleModalMC} className="bouton_mc" onMouseEnter={() => setHoverIconOnce(true)}>
                <FontAwesomeIcon icon={faBars} />
            </button>

            {modal && (
                <div className="modal_mc">
                    {done ? (
                        <ul>
                            <li>
                                <NavLink
                                    end
                                    to={url}
                                    style={{
                                        color: "black",
                                        textDecoration: "none",
                                    }}
                                >
                                    Visualiser
                                </NavLink>
                            </li>
                            <li onClick={handlePrint}>Télécharger</li>
                            <li>
                                <ComponentToPrint
                                    ref={componentRef}
                                    id={pt.id}
                                    pt={pt}
                                    eu={eu}
                                    ee={ee}
                                    eeCon={eeCon}
                                    st={st}
                                    stCon={stCon}
                                    orga={orga}
                                    reco={reco}
                                    orga_sec={orga_sec}
                                    firstKeys={firstPartKeys}
                                    secondKeys={secondPartKeys}
                                    modOpComInfos={modOpComInfos}
                                    risqueTitre={theRisqueTitre}
                                    risques={risques}
                                    validateur={validateur}
                                    avisChantier={avisChantier}
                                    commentaires={commentaires}
                                    signatairesFin={signatairesFin}
                                />
                                <button onClick={handlePrint}>Imprimer</button>
                            </li>

                            {pt.status !== "clotures" && <li onClick={handleCloture}>Clôturer</li>}
                            <li>
                                <EnvoiMail show={showSend} pt={pt} />
                            </li>

                            {/* <li>Créer une TC associée</li> */}
                            <li onClick={handleCopy}>Créer une copie</li>
                        </ul>
                    ) : (
                        <div style={{ display: "relative" }}>
                            <Loader />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
export default MenuContextuel;
